import React from 'react'
import PropTypes from 'prop-types'

import CollapseStyled from './styled'
import ArrowUpImg from '../../assets/images/collapse/dropdown-up.svg'
import ArrowDownImg from '../../assets/images/collapse/dropdown-down.svg'

class Collapse extends React.PureComponent {
  state = {
    isCollapse: this.props.defaultCollapse,
  }

  toggleCollapse = () => {
    this.setState(state => ({ isCollapse: !state.isCollapse }))
  }

  getTitleContainer() {
    let className = 'collapse-title-container'
    className += this.state.isCollapse ? '' : ' open'
    const imgSrc = this.state.isCollapse ? ArrowDownImg : ArrowUpImg
    return (
      <div className={className}>
        <div id={`${this.props.id}-title`} data-test={`${this.props.id}-title`} className="collapse-title">
          {this.props.title}
        </div>
        <div className="liner" />
        <div className="icon-box">
          <div
            id={`${this.props.id}-arrow-icon`}
            data-test={`${this.props.id}-arrow-icon`}
            className="collapse-icon-container"
            onClick={this.toggleCollapse}
          >
            <img src={imgSrc} />
          </div>
        </div>
      </div>
    )
  }

  getContent() {
    let className = 'collapse-content-container'
    className += this.state.isCollapse ? '' : ' open'
    return (
      <div id={`${this.props.id}-content`} data-test={`${this.props.id}-content`} className={className}>
        {this.props.children}
      </div>
    )
  }

  render() {
    return (
      <CollapseStyled className={this.props.className} id={this.props.id} data-test={this.props.id}>
        {this.getTitleContainer()}
        {this.getContent()}
      </CollapseStyled>
    )
  }
}

Collapse.defaultProps = {
  id: 'collapse',
  title: '',
  defaultCollapse: false,
}

Collapse.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultCollapse: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element]).isRequired,
}

export default Collapse
