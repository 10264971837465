import styled from 'styled-components'

export default styled.div`
  width: 100%;
  .collapse-title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .collapse-title {
      font-size: 18px;
      color: #fafafa;
    }
    .liner {
      display: flex;
      flex: 1;
      width: 100%;
      margin: 0 16px;
      &:after {
        content: '';
        width: 100%;
        height: 1px;
        background: #981214;
      }
    }
    .icon-box {
      display: flex;
      justify-content: center;
      width: 40px;
    }
    .collapse-icon-container {
      width: 24px;
      height: 24px;
      cursor: pointer;
      background: #981214;
      border-radius: 4px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
  .collapse-content-container {
    height: 0px;
    overflow: hidden;
    color: white;
    &.open {
      height: auto;
      overflow: visible;
      margin-top: 16px;
    }
  }
`
